<template>
	<div>
		<v-layout class="p-4 border-bottom-light-grey min-height-57px">
			<v-flex md5 class="font-level-3-bold my-auto">
				<span class="detail-svg-icon mr-2">
					<!--begin::Svg Icon-->
					<inline-svg :src="$assetURL('media/custom-svg/reservation-color.svg')" />
					<!--end::Svg Icon-->
				</span>
				{{ getTitle() }}
			</v-flex>
			<v-flex md7 v-if="false" class="font-level-3-bold my-auto d-flex justify-content-end">
				<template v-if="status == 'Approved' && btnPermission">
					<v-btn
						v-if="!isRecieve"
						color="blue darken-4 white--text mr-2"
						tile
						depressed
						@click="fullyRecieved()"
					>
						Fully Recieve
					</v-btn>
					<template v-if="isRecieve">
						<v-btn color="red lighten-1 white--text" tile class="ml-2" depressed @click="forceClose()">
							Force Close
						</v-btn>
						<v-btn color="grey lighten-3 ml-2" tile depressed @click="isRecieve = false">Cancel</v-btn>
						<v-btn
							color="blue darken-4 white--text"
							class="ml-2"
							tile
							depressed
							@click="partiallyRecieved()"
							>Save</v-btn
						>
					</template>
					<template v-else>
						<v-btn color="blue darken-4 white--text" tile depressed @click="isRecieve = true"
							>Partially Recieve</v-btn
						>
					</template>
				</template>
			</v-flex>
		</v-layout>
		<Loading v-if="contentLoading"></Loading>
		<div v-else class="overflow-y" style="max-height: calc(100vh - 306px)">
			<table width="100%" class="detail-table table-head-sticky">
				<thead>
					<tr>
						<th width="30" class="p-2 blue lighten-4 text-center"></th>
						<th width="50" class="p-2 blue lighten-4 text-center">#</th>
						<th class="p-2 blue lighten-4">Item</th>
						<th class="p-2 blue lighten-4 text-center">Total</th>
						<th class="p-2 blue lighten-4 text-center">Pending</th>
						<th class="p-2 blue lighten-4 text-center">Recieved</th>
					</tr>
				</thead>
				<!-- <tbody v-if="line_items.length"> -->
				<Draggable
					v-if="line_items.length"
					tag="tbody"
					v-model="line_items"
					class="draggable-group"
					handle=".draggable-drag-icon"
				>
					<tr v-for="(row, index) in line_items" :key="index">
						<td class="p-2 border-top-light-grey text-center">
							<v-icon color="blue darken-4" class="cursor-move draggable-drag-icon">mdi-drag</v-icon>
						</td>
						<td class="p-2 border-top-light-grey text-center">
							{{ index + 1 }}
						</td>
						<td class="p-2 border-top-light-grey">
							{{ row.product_name }}
						</td>
						<td class="p-2 border-top-light-grey text-center">
							<v-chip label>
								{{ row.required_qty }}
							</v-chip>
						</td>
						<td class="p-2 border-top-light-grey orange--text fw-500 text-center">
							<v-chip label color="orange white--text">
								{{ row.pending_qty }}
							</v-chip>
						</td>
						<td class="p-2 border-top-light-grey">
							<!-- {{ row.recieved }} -->
							<InputEdit v-model="row.recieved" v-on:update="updateValue($event)" v-if="false">
							</InputEdit>
							<div style="width: 80px" class="mx-auto">
								<template v-if="isRecieve">
									<QuantityInput
										type="number"
										v-model.number="line_items[index].recieve_qty"
										v-on:change="updateQty(index, row.pending_qty)"
										hide-details
										class="mt-0"
										:min="0"
									></QuantityInput>
								</template>
								<div style="min-height: 30px" class="px-3 py-1" v-else>
									<v-chip label>
										{{ line_items[index].received_items }}
									</v-chip>
								</div>
							</div>
						</td>
					</tr>
				</Draggable>
				<!-- </tbody> -->
				<tfoot v-else>
					<tr>
						<td colspan="5">
							<p class="m-0 row-not-found text-center py-3">
								<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
								Uhh... There <span class="text-lowercase">are no {{ getTitle() }} at the moment.</span>
							</p>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<Dialog :dialog="saveRecievedDialog" :dialog-width="700" dense v-if="saveRecievedDialog">
			<template v-slot:title>
				<span>Save As Recieved Item</span>
			</template>
			<template v-slot:body>
				<div class="px-4 pb-3">
					<v-form
						ref="grnRecieveForm"
						v-model.trim="formValid"
						lazy-validation
						v-on:submit.stop.prevent="createRecievedItem"
					>
						<v-row>
							<v-col md="3" class="my-auto py-0">
								<label for="grn-number" class="btx-label mt-2">GRN Number</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextInput
									hide-details
									:disabled="pageLoading"
									readonly
									:loading="pageLoading"
									id="grn-number"
									placeholder="GRN Number"
									v-model="item_detail.grn_number"
									:rules="[
										vrules.required(item_detail.grn_number, 'GRN Number'),
										barcodeError ? false : true,
									]"
									:class="{
										required: !item_detail.grn_number,
									}"
									v-on:change="validateBarcode(item_detail.grn_number, 'grn')"
								></TextInput>
								<span v-if="barcodeError" class="red--text text--darken-1 font-small">{{
									barcodeError
								}}</span>
							</v-col>
							<v-col md="3" class="py-0">
								<label for="remark" class="btx-label mt-2">Remark</label>
							</v-col>
							<v-col md="9" class="py-0">
								<TextAreaInput
									:disabled="pageLoading"
									:loading="pageLoading"
									id="remark"
									placeholder="Remark"
									v-model="item_detail.remark"
								></TextAreaInput>
							</v-col>
							<template v-if="force_close">
								<v-col md="3" class="py-0">
									<label for="secutrity-code" class="btx-label mt-4 required">Security Code</label>
								</v-col>
								<v-col md="9" class="py-0">
									<TextInput
										hide-details
										:disabled="pageLoading"
										:loading="pageLoading"
										id="secutrity-code"
										placeholder="Security Code"
										v-model="item_detail.security_code"
										:rules="[vrules.required(item_detail.security_code, 'Security Code')]"
										:class="{
											required: !item_detail.security_code,
										}"
									></TextInput>
								</v-col>
							</template>
							<v-col md="3" class="mt-2 py-0">
								<label for="attachemnt" class="btx-label mt-2">Attachment</label>
							</v-col>
							<v-col md="9" class="py-0">
								<FileUpload v-model="item_detail.attachment" :allow-add-more="false"></FileUpload>
							</v-col>
						</v-row>
					</v-form>
				</div>
			</template>
			<template v-slot:action>
				<v-btn color="mr-2" tile depressed @click="closeDialog()">Close</v-btn>
				<v-btn
					color="blue darken-4 white--text"
					tile
					depressed
					:loading="pageLoading"
					:disabled="pageLoading"
					@click="createRecievedItem()"
					>Save</v-btn
				>
			</template>
		</Dialog>
	</div>
</template>
<script>
import { mapGetters } from "vuex";
import TextInput from "@/view/components/TextInput";
import QuantityInput from "@/view/components/QuantityInput";
import TextAreaInput from "@/view/components/TextAreaInput";
import Loading from "@/view/components/Loading";
import InputEdit from "@/view/components/InputEdit";
import FileUpload from "@/view/components/FileUpload";
import { GetLineItem, GetGrnNumber, CreateRecievedPOItem } from "@/core/lib/purchase-order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import Draggable from "vuedraggable";
import Dialog from "@/view/components//Dialog";
// import { EventBus } from "@/core/event-bus/event.bus";
import { map, filter } from "lodash";
export default {
	props: {
		type: {
			type: String,
			default: null,
		},
		typeText: {
			type: String,
			default: null,
		},
		uuid: {
			type: String,
			default: null,
		},
		id: {
			type: Number,
			default: 0,
		},
		btnPermission: {
			type: Boolean,
			default: false,
		},
		status: {
			type: String,
			default: "all",
		},
	},
	data() {
		return {
			contentLoading: false,
			formValid: true,
			line_items: [],
			item_detail: {
				uuid: null,
				grn_number: null,
				remark: null,
				security_code: null,
				attachment: null,
			},
			isRecieve: false,
			saveRecievedDialog: false,
			recievingUrl: null,
			status_type: null,
			force_close: 0,
			pageLoading: false,
		};
	},
	methods: {
		getTitle() {
			return "Pending";
		},
		updateValue(value) {
			console.log("UpdateValue", value);
		},
		updateQty(index, total) {
			if (this.line_items[index].recieve_qty > total) {
				this.line_items[index].recieve_qty = total;
			}
		},

		async getPendingItem() {
			const param = {
				purchase_order: this.id,
				option: "pending_line_items",
			};
			this.contentLoading = true;
			const data = await GetLineItem(param);
			const _line_item = filter(data.line_items, (row) => {
				return row.pending_qty;
			});
			this.line_items = map(_line_item, (row) => {
				return { ...row, recieve_qty: 0 };
			});
			this.contentLoading = false;
		},
		async partiallyRecieved() {
			this.saveRecievedDialog = true;
			const payload = { purchase_order: this.id };
			const { barcode, uuid } = await GetGrnNumber(payload);
			this.item_detail.grn_number = barcode;
			this.item_detail.uuid = uuid;
			this.recievingUrl = "receive-line-item";
			this.status_type = "partial";
			this.force_close = 0;
		},
		async fullyRecieved() {
			this.saveRecievedDialog = true;
			const payload = { purchase_order: this.id };
			const { barcode, uuid } = await GetGrnNumber(payload);
			this.item_detail.grn_number = barcode;
			this.item_detail.uuid = uuid;
			this.recievingUrl = "receive-line-item";
			this.status_type = "full";
			this.force_close = 0;
		},
		async forceClose() {
			this.saveRecievedDialog = true;
			const payload = { purchase_order: this.id };
			const { barcode, uuid } = await GetGrnNumber(payload);
			this.item_detail.grn_number = barcode;
			this.item_detail.uuid = uuid;
			this.recievingUrl = "receive-line-item";
			this.status_type = "partial";
			this.force_close = 1;
		},
		async createRecievedItem() {
			const _this = this;

			const formErrors = _this.validateForm(_this.$refs.grnRecieveForm);
			if (formErrors.length) {
				_this.$store.commit(SET_ERROR, _this.errors.concat(formErrors));
			}

			if (!_this.$refs.grnRecieveForm.validate()) {
				return false;
			}

			const filter_item = _this.line_items.filter((row) => {
				return row.recieve_qty;
			});
			const _items = filter_item.map((row) => {
				return {
					item_id: row.line_item,
					product: row.product,
					quantity: row.recieve_qty,
				};
			});
			const payload = {
				uuid: _this.item_detail.uuid,
				purchase_order: _this.id,
				grn_barcode: _this.item_detail.grn_number,
				remark: _this.item_detail.remark,
				security_code: _this.item_detail.security_code,
				customer: null,
				project: null,
				budget_category: null,
				assign: null,
				line_items: _items,
				type: _this.status_type,
				force_close: _this.force_close,
				files: _this.item_detail.attachment,
			};
			if (this.force_close) {
				if (!_this.item_detail.security_code) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: "Security code required." }]);
					return false;
				}
			}
			if (_this.status_type == "partial" && this.force_close == 0) {
				if (_items.length == 0) {
					_this.$store.commit(SET_ERROR, [{ model: true, message: "Recieve Quantity is required." }]);
					//_this.saveRecievedDialog = false;
					return false;
				}
			}
			_this.pageLoading = true;
			try {
				await CreateRecievedPOItem(_this.recievingUrl, payload);
				_this.getPendingItem();
				_this.$emit("update", true);
				_this.saveRecievedDialog = false;
				_this.isRecieve = false;
				_this.$emit("success", true);
			} catch (error) {
				console.log(error);
			} finally {
				_this.pageLoading = false;
			}
		},
		closeDialog() {
			(this.item_detail = {
				grn_number: null,
				remark: null,
				security_code: null,
				attachment: null,
			}),
				(this.saveRecievedDialog = false);
		},
		/* This method called from Parent components */
		recieveItem() {
			//console.log(123);
			this.isRecieve = true;
		},
	},
	computed: {
		...mapGetters(["errors"]),
	},
	components: {
		TextInput,
		QuantityInput,
		TextAreaInput,
		InputEdit,
		FileUpload,
		Dialog,
		Draggable,
		Loading,
	},
	// beforeDestroy() {
	// 	EventBus.$off("reload:line-item");
	// },
	mounted() {
		if (this.id) {
			this.getPendingItem();
		}
		// EventBus.$on("reload:line-item", () => {
		// 	this.getPendingItem();
		// });
	},
};
</script>
