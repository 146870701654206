import ApiService from "@/core/services/api.service";
import { getToken } from "@/core/services/jwt.service.js";

export const GetPurchaseOrder = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`request-order/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetCustomer = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`purchase-order/get-options`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetLineItem = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`line-item-options-request-po`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DeletePOLineItem = (id) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`line-item/${id}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetGrnNumber = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`grn`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetGRNDetail = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`detail/${uuid}/grn`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetGrnPDF = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`generate-pdf/purchase-order/grn/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const DownloadPDF = ({ url, uuid }) => {
	return new Promise((resolve, reject) => {
		const token = getToken();
		const api_url = process.env.VUE_APP_API_URL;
		const pdf_url = `${api_url}${url}${uuid}?token=${token}`;
		const pdf = window.open(pdf_url, "_blank");
		if (pdf) {
			resolve(true);
		} else {
			reject(false);
		}
	});
};
export const CreateRecievedPOItem = (url, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(url, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const UpdateChangePrice = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`price-update/purchase-order/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const DeleteService = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.delete(`purchase-order/${uuid}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateRequestPo = (params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post("request-order", params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdatePurchaseOrder = (uuid, params) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.put(`request-order/${uuid}`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
